import React, {useState, useEffect, useRef} from "react";

import "./LandingFeatures.css";
import GraphReportsScatter from "../../tool/graphics/GraphReportsScatter.js";
import GraphReportsBar from "../../tool/graphics/GraphReportsBar.js";
import GraphReportsTS from "../../tool/graphics/GraphReportsTS.js";
import Smiley from "../../tool/graphics/Smiley.js";

import TypeTag from "../../tool/tags/TypeTag.js";

const tagNames = {
    "non-emergent": "Non-Emergent",
    "non-acute": "Non-Acute/Other",
    "refusal": "Refusal",
    "cancelled": "Cancelled/No Patient",
    "general-emergent": "Emergent",
    "stroke": "Stroke",
    "circulatory": "Circ",
    "arrest": "Arrest",
    "stemi": "STEMI",
    "trauma": "Trauma",
    "respiratory": "Resp",
    "seizure": "Seizure",
    "syncope": "Syncope",
    "tbi": "TBI",
    "pediatrics": "Ped",
    "hypoglycemia": "LBG",
    "airway": "Airway",
    "asthma": "Asthma"
};

const sortedTagNames = Object.fromEntries(
    Object.entries(tagNames).sort()
);

const LandingFeatures = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    // store graph components into a useRef so that they only load once
    const componentsRef = useRef([<GraphReportsScatter/>, <GraphReportsBar/>, <GraphReportsTS/>]);

    useEffect(() => { // iterate through the graph components every 5 seconds with index
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % componentsRef.current.length);
        }, 5000);
    
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    // var header = document.getElementById("feature-header");
    // console.log(header);
    // console.log(document.documentElement);
    
    // document.addEventListener("scroll", function () {
    //     if (header.scrollTop + header.scrollHeight < document.documentElement.scrollTop + document.documentElement.scrollHeight) {

    //     }
    // })

    return (
        <div className="landing-features content">
            <h2 id="feature-header">A Two-Stage Approach to EMS Quality</h2>
            
            <div className="section-content">

                <div className="qa row qa-row">
                    <div className="col">
                        <img src={require('../../../images/logo-dark-QA.png')} className="logo qalogo "/>
                        <p className="desc">Quality Assurance is necessary for run-to-run compliance. CADMUS QA makes it easier to check over reports by taking care of trivial test cases, saving time and allowing you to focus on the responses that matter most to patients.</p>
                        <div className="features">
                            <div className="feature">
                                <i className="fa-solid fa-file-circle-check"></i>
                                <p>Detect errors in care</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-xmark"></i>
                                <p>No PHI/PII required</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-display"></i>
                                <p>View results on the web</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-dollar-sign"></i>
                                <p>Improve billing</p>
                            </div>
                            <div className="feature">
                            <i className="fa-solid fa-square-check"></i>
                                <p>NEMSIS v3.5.0</p>
                            </div>
                        </div>
                        <p className="checks"><strong>Our checks cover a comprehensive set of response cases:</strong></p>
                        <div className="tags">
                            {Object.keys(sortedTagNames).map((type) => (
                                <TypeTag reportType={type} isButton={false}/>
                            ))}
                        </div>
                    </div>
                    <div className="carousel">
                        <div className="images-container">
                            <img src={require('../../../images/qa-screenshot.png')} />
                        </div>
                    </div>
                </div>

                <hr />

                <div className="qi row qi-row">
                    <div className="col">
                        <img src={require('../../../images/logo-dark-QI.png')} className="logo qilogo"/>
                        <p className="desc">Quality Improvement is the essential second stage of guaranteeing a high level of care. CADMUS QI cuts through the noise, presenting clear, data-driven findings that help you and your medics improve agency practices.</p>
                        <div className="features">
                            <div className="feature">
                                <i className="fa-solid fa-chart-line"></i>
                                <p>Visualize NEMSQA measures</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-clipboard-list"></i>
                                <p>Set goals to steer agency practices</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-calendar-days"></i>
                                <p>Generated quarterly and annually</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-envelope-open-text"></i>
                                <p>Share with team and stakeholders</p>
                            </div>
                            <div className="feature">
                                <i className="fa-solid fa-award"></i>
                                <p>Get national program recognition </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel qi-carousel">
                        <div className="images-container">
                        {/* <div> */}
                            <img src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                            {/* <GraphReportsScatter/> */}
                            {/* <GraphReportsBar/> */}
                            {/* <GraphReportsTS/> */}
                            {/* <Smiley/> */}
                            {/* {componentsRef.current.map((component, index) => (
                                <div key={index} style={{ display: currentIndex === index ? 'block' : 'none' }}>
                                    {component}
                                </div>
                            ))} */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LandingFeatures;
import React, { useState, useEffect } from 'react';

import ReportStatusModule from '../modules/ReportStatusModule.js';
import ReportInfoModule from '../modules/ReportInfoModule.js';
import ReportAiFlagsModule from '../modules/ReportAiFlagsModule.js';
import ReportTimesModule from '../modules/ReportTimesModule.js';
import ReportNarrativeModule from '../modules/ReportNarrativeModule.js';
import ReportAiNarrativeModule from '../modules/ReportAiNarrativeModule.js';
import ReportChecksModule from '../modules/ReportChecksModule.js';
import './Box.css';

const CheckResultsBox = ({user, reportId, isSmallScreen}) => {
    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>
            <h1 className='box-title'> Report Feedback </h1>
            <ReportStatusModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
            <ReportInfoModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
            {/* {user.subscriptions.includes('CADMUS_AUDITOR') && <ReportAiFlagsModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>} */}
            <ReportTimesModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />
            {!user.subscriptions.includes('CADMUS_AUDITOR') && <ReportNarrativeModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>}
            {user.subscriptions.includes('CADMUS_AUDITOR') && <ReportAiNarrativeModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>}
            {/* {user.subscriptions.includes('CADMUS_QA') && <ReportChecksModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />} */}
            <ReportChecksModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />
        </div>
    );
}

export default CheckResultsBox;